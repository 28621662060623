.amplify-alert[data-variation="error"] {
  background-color: whitesmoke;
  color: black;
}

/* The below is the CSS property to allow the users only lowercase chars. */
#amplify-id-0 {
  text-transform: lowercase;
}

.amplify-button--primary {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 0.4rem;
  background-color: #4065ff;
  color: #fff !important;
  text-align: center;
  /* padding: 1.3rem 2rem; */
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.amplify-field-group__field-wrapper input[type="submit"] {
  border-radius: 0.5rem;
  border: 0.1rem solid #cbd4db;
  width: 100%;
  color: #3c3c3c;
  padding: 1rem 0.9rem 0.9rem;
  -webkit-transition: border-color 0.4s, color 0.4s;
  -o-transition: border-color 0.4s, color 0.4s;
  transition: border-color 0.4s, color 0.4s;
}
